import { Link } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";

const History = ({ t }: WithTranslation) => {
  return (
    <div className="history">
      <div className="history__main">
        <h2 className="history__title">{t("our_story")}</h2>
        <p className="history__description">{t("our_story_details")}</p>
        <div className="history__box">
          <Link className="history__link" to="/contact">
            {t("contact")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(History);