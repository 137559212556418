import menuIcon from "../../assets/hamburger.png";
import { useAPI } from "../../context";

const Hamburger = () => {

  const { setIsHamburgerMenu } = useAPI();

  const menu = () => {
    setIsHamburgerMenu((prev) => !prev);
  };

  return (
    <div className="hamburger">
      <button className="hamburger__button" onClick={menu}>
        <img className="hamburger__icon" src={menuIcon} alt="menu" />
      </button>
    </div>
  );
};

export default Hamburger;
