import { useState, useContext } from "react";
import { APIContext, APIContextProviderProps } from "./@types.context";

export const APIContextProvider = ({ children }: APIContextProviderProps) => {
  const [isHamburgerMenu, setIsHamburgerMenu] = useState<boolean>(false);

  return (
    <APIContext.Provider
      value={{
        isHamburgerMenu,
        setIsHamburgerMenu
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(APIContext);
  return context;
}