import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import Menu from "../Menu/Menu";

const Navigation = () => {
  return (
    <div className="navigation">
      <Link to="/" className="navigation__link">
        <img className="navigation__logo" src={logo} alt="logo" />
      </Link>
      <Menu />
    </div>
  );
};

export default Navigation;
