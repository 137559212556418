import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import privacyPolicy from "../../assets/privacyPolicy.png";

const PrivacyPolicyEN = () => {
  return (
    <div className="privacy-policy-container">
      <ToastContainer />
      <div className="contact__figure">
        <LazyLoadImage
          className="contact__image"
          width="100%"
          height="100%"
          src={privacyPolicy}
          alt="flower"
          effect="blur"
        />
        <h1 className="privacy__title">Privacy Policy</h1>
      </div>
      <p>
        This Privacy Policy describes the rules for processing your information,
        including personal data and cookies.
      </p>

      <section>
        <h2>1. General Information</h2>
        <p>
          This policy applies to the website at:{" "}
          <a
            href="https://vesselhost.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            vesselhost.com
          </a>
          . The operator of the website and the Data Controller is: Vesselhost
          Sp. z o.o., Wajdeloty 27/1A, 80-437 Gdańsk.
        </p>
        <p>
          Contact email address:{" "}
          <a href="mailto:vesselhost1@gmail.com">vesselhost1@gmail.com</a>
        </p>
        <p>
          The Operator is the Data Controller of your personal data in relation
          to data provided voluntarily on the website.
        </p>
        <p>
          The website uses personal data for the following purposes:
          <ul>
            <li>Handling queries via the contact form</li>
            <li>Providing the requested services</li>
            <li>Displaying offers or information</li>
          </ul>
        </p>
        <p>
          The website collects information about users and their behavior in the
          following ways:
          <ul>
            <li>
              Through voluntarily provided data in forms, which are entered into
              the Operator's systems.
            </li>
            <li>By saving cookies (small text files) in the user's device.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>2. Selected Data Protection Methods Used by the Operator</h2>
        <p>
          Login and data entry areas are protected using SSL encryption. As a
          result, personal and login information entered on the website is
          encrypted on the user's device and can only be read by the destination
          server.
        </p>
        <p>
          Personal data stored in the database is encrypted in a way that only
          the Operator, who holds the encryption key, can read it. This protects
          the data in case the database is stolen.
        </p>
        <p>
          User passwords are stored in a hashed form. The hashing function works
          unidirectionally – it is not possible to reverse it, which is the
          current standard for storing user passwords.
        </p>
        <p>The Operator periodically changes its administrative passwords.</p>
        <p>To protect data, the Operator regularly performs backups.</p>
        <p>
          Regular updates of all software used by the Operator to process
          personal data are carried out to ensure data protection.
        </p>
      </section>

      <section>
        <h2>3. Hosting</h2>
        <p>
          The website is hosted on servers provided by the hosting company
          cyberFolks.pl. The hosting company may keep logs for technical
          reasons. The logs may include:
          <ul>
            <li>Requested resource identifiers (URLs),</li>
            <li>Time of the request,</li>
            <li>Time of the response sent,</li>
            <li>Client station name - identification via the HTTP protocol,</li>
            <li>Error codes when processing the HTTP request,</li>
            <li>
              Referrer URL (previous website) – if the user came from another
              website,
            </li>
            <li>Information about the user's browser,</li>
            <li>IP address information,</li>
            <li>
              Diagnostic information related to self-service ordering processes
              on the website,
            </li>
            <li>
              Information related to email communication to/from the Operator.
            </li>
          </ul>
        </p>
      </section>

      <section>
        <h2>4. Your Rights and Additional Information on Data Usage</h2>
        <p>
          In certain situations, the Administrator may share your personal data
          with other entities if it is necessary to perform the contract with
          you or to fulfill legal obligations of the Administrator. This
          includes the following types of recipients: operators of online chat
          solutions, marketing companies working on behalf of the Administrator.
        </p>
        <p>
          Your personal data will be processed by the Administrator only for as
          long as necessary to carry out the tasks related to them, as required
          by separate regulations (e.g., accounting). In the case of marketing
          data, the data will not be processed for more than 3 years.
        </p>
        <p>
          You have the right to request from the Administrator:
          <ul>
            <li>Access to your personal data,</li>
            <li>Correction of your data,</li>
            <li>Deletion of your data,</li>
            <li>Limitation of processing,</li>
            <li>Transfer of data.</li>
          </ul>
        </p>
        <p>
          You have the right to object to the processing of your personal data
          if it is based on the legitimate interests pursued by the
          Administrator, including profiling. However, this right cannot be
          exercised if there are important legal grounds for processing, which
          outweigh your interests, rights, and freedoms, such as the
          establishment, exercise, or defense of legal claims.
        </p>
        <p>
          You have the right to file a complaint with the President of the
          Personal Data Protection Office at ul. Stawki 2, 00-193 Warsaw,
          Poland.
        </p>
        <p>
          Providing personal data is voluntary, but necessary for using the
          website.
        </p>
        <p>
          Automated decision-making, including profiling, may take place in
          relation to you for the purposes of providing the services under the
          agreement and for the purpose of direct marketing by the
          Administrator.
        </p>
      </section>

      <section>
        <h2>5. Information in Forms</h2>
        <p>
          The website collects information voluntarily provided by the user,
          including personal data, if provided.
        </p>
        <p>
          The website may record information about the connection parameters
          (date, IP address).
        </p>
        <p>
          In some cases, the website may store an identifier linking the data in
          the form with the user's email address. In this case, the user's email
          address will appear in the URL of the page containing the form.
        </p>
        <p>
          Data provided in the form is processed for the purpose indicated by
          the specific form, e.g., to process a service request, contact, or
          service registration. Each form context and description clearly
          indicates its purpose.
        </p>
      </section>

      <section>
        <h2>6. Administrator's Logs</h2>
        <p>
          Information about user behavior on the website may be logged. These
          logs are used for website administration purposes.
        </p>
      </section>

      <section>
        <h2>7. Cookie Information</h2>
        <p>
          The website uses cookies. Cookies are small text files stored on the
          user's device and are used to enhance the user's experience on the
          website.
        </p>
        <p>
          The entity placing cookies on the user's device and accessing them is
          the operator of the website.
        </p>
        <p>
          Cookies are used for the following purposes:
          <ul>
            <li>
              Maintaining user sessions on the website (after logging in), so
              the user doesn't need to re-enter their credentials on every page.
            </li>
          </ul>
        </p>
        <p>
          The website uses two main types of cookies: "session" cookies, which
          are temporary and remain on the user's device until the session ends,
          and "persistent" cookies, which remain on the device until a specified
          expiration date or until manually deleted by the user.
        </p>
      </section>

      <section>
        <h2>8. Managing Cookies – How to Express and Withdraw Consent?</h2>
        <p>
          If the user does not want to receive cookies, they can change their
          browser settings. Please note that disabling essential cookies may
          make it difficult or impossible to use certain features of the
          website.
        </p>
        <p>
          To manage cookies, choose your browser from the list below and follow
          the instructions:
        </p>
        <ul>
          <li>Edge</li>
          <li>Internet Explorer</li>
          <li>Chrome</li>
          <li>Safari</li>
          <li>Firefox</li>
          <li>Opera</li>
        </ul>
        <p>Mobile devices:</p>
        <ul>
          <li>Android</li>
          <li>Safari (iOS)</li>
          <li>Windows Phone</li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicyEN;
