import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Animated from "../Animated/Animated";

const Entry = ({ t }: WithTranslation) => {
  return (
    <div className="entry">
      <Animated />
      <div className="entry__main">
        <h1 className="entry__title">{t("technological_bridges")}</h1>
        <p className="entry__desciption">
          {t("vesselhost_description")}
        </p>
        <div className="entry__box">
          <Link className="entry__link" to="/contact">
            {t("contact")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Entry);
