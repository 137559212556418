import { withTranslation, WithTranslation } from "react-i18next";

const Faq = ({ t }: WithTranslation) => {
  return (
    <div className="faq">
      <div className="faq__wrapper">
        <h2 className="faq__title">FAQ</h2>
        <div className="faq__box">
          <p className="faq__description">{t("services_offered")}</p>
          <p className="faq__text">{t("vesselhost_services")}</p>
          <p className="faq__description">{t("vesselhost_investment_value")}</p>
          <p className="faq__text">{t("vesselhost_value_approach")}</p>
          <p className="faq__description">{t("vesselhost_distinction")}</p>
          <p className="faq__text">{t("vesselhost_distinction_details")}</p>
          <p className="faq__description">
            {t("close_collaboration_and_communication")}
          </p>
          <p className="faq__text">{t("direct_contact_and_fast_response")}</p>
          <p className="faq__description">
            {t("flexibility_and_adaptability")}
          </p>
          <p className="faq__text">{t("quick_adaptation")}</p>
          <p className="faq__description">
            {t("affordable_pricing_without_compromise")}
          </p>
          <p className="faq__text">{t("competitive_pricing_with_quality")}</p>
          <p className="faq__description">{t("honesty_and_spirit")}</p>
          <p className="faq__text">{t("honesty_and_ethics")}</p>
          <p className="faq__description">{t("technology_research")}</p>
          <p className="faq__text">{t("innovative_solutions")}</p>

          <p className="faq__text">
            {t("modern_technology_and_business_needs")}
          </p>

          <p className="faq__description">{t("digital_transformation_help")}</p>
          <p className="faq__text">{t("digital_transformation_support")}</p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Faq);
