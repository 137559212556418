import developerOnePhoto from "../../assets/developerOnePhoto.jpg";
import developerTwoPhoto from "../../assets/developerTwoPhoto.jpg";
import ceo from "../../assets/ceo.jpg";
import { withTranslation, WithTranslation } from "react-i18next";

const Team = ({ t }: WithTranslation) => {
  return (
    <div className="team">
      <div className="team__wrapper">
        <h2 className="team__title">{t("team")}</h2>
        <div className="team__box">
          <div className="team__member">
            <img
              className="team__avatar"
              src={developerOnePhoto}
              alt="member"
            ></img>
            <p className="team__name">Jakub Makowski</p>
            <p className="team__description">Frontend developer</p>
            <p className="team__description">
              {t("frontend_developer_description")}
            </p>
          </div>
          <div className="team__member">
            <img className="team__avatar" src={ceo} alt="member"></img>
            <p className="team__name">Paweł Leśniak</p>
            <p className="team__description">CEO / {t("project_manager")}</p>
            <p className="team__description">
              {t("project_manager_description")}
            </p>
          </div>
          <div className="team__member">
            <img
              className="team__avatar"
              src={developerTwoPhoto}
              alt="member"
            ></img>
            <p className="team__name">Mariusz Lejkowski</p>
            <p className="team__description">Backend developer</p>
            <p className="team__description">
              {t("backend_developer_description")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Team);
