import { withTranslation, WithTranslation } from "react-i18next";
import officeTwo from "../../assets/officeTwo.png";
import computer from "../../assets/computer.png";
import handshake from "../../assets/handshake.png";
import { Link } from "react-router-dom";

const AboutUsSection = ({ t }: WithTranslation) => {
  return (
    <div className="aboutUsSection">
      <div className="aboutUsSection__main">
        <div className="aboutUsSection__figure">
          <img
            className="aboutUsSection__image"
            src={officeTwo}
            alt="officeTwo"
          />
          <img
            className="aboutUsSection__image"
            src={handshake}
            alt="handshake"
          />
          <img
            className="aboutUsSection__image"
            src={computer}
            alt="computer"
          />
        </div>
        <div className="aboutUsSection__box">
          <p className="aboutUsSection__title">{t("why_us")}</p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("flexibility")}
          </p>
          <p className="aboutUsSection__description">
            {t("responsive_and_collaborative")}
          </p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("creativity")}
          </p>
          <p className="aboutUsSection__description">{t("our_team")}</p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("fast_delivery")}
          </p>
          <p className="aboutUsSection__description">{t("agile_execution")}</p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("transparency")}
          </p>
          <p className="aboutUsSection__description">
            {t("clear_communication")}
          </p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("custom_pricing")}
          </p>
          <p className="aboutUsSection__description">{t("tailored_pricing")}</p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("close_client_relationship")}
          </p>
          <p className="aboutUsSection__description">
            {t("personalized_collaboration")}
          </p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("focus_on_quality")}
          </p>
          <p className="aboutUsSection__description">
            {t("commitment_to_quality")}
          </p>
          <p className="aboutUsSection__description aboutUsSection__description--decoration">
            {t("less_bureaucracy")}
          </p>
          <p className="aboutUsSection__description">
            {t("streamlined_processes")}
          </p>
          <Link className="aboutUsSection__link" to="/aboutUs">
            {t("more")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AboutUsSection);
