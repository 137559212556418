/* eslint-disable react-hooks/exhaustive-deps */
import { withTranslation, WithTranslation } from "react-i18next";
import poland from "../../assets/poland.png";
import unitedKingdom from "../../assets/united-kingdom.png";
import { Link } from "react-router-dom";
import Hamburger from "../Hamburger/Hamburger";
import { useAPI } from "../../context";
import i18n from "i18next";
import { useEffect, useRef } from "react";

const Menu = ({ t }: WithTranslation) => {
  const { isHamburgerMenu, setIsHamburgerMenu } = useAPI();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsHamburgerMenu(false);
    }
  };

  useEffect(() => {
    if (isHamburgerMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isHamburgerMenu]);

  return (
    <div className="menu" ref={menuRef}>
      <ul className={`${isHamburgerMenu ? "menu__list" : "menu__listTwo"}`}>
        <li className="menu__item">
          <Link className="menu__link" to="/">
            {t("homePage")}
          </Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/aboutUs">
            {t("aboutUs")}
          </Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/priceList">
            {t("priceList")}
          </Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/offer">
            {t("offer")}
          </Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/projects">
            {t("projects")}
          </Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/contact">
            {t("contact")}
          </Link>
        </li>
        <div className="menu__flags">
          <li className="menu__item" onClick={() => handleLanguageChange("pl")}>
            <img className="menu__icon" src={poland} alt="poland" />
          </li>
          <li className="menu__item" onClick={() => handleLanguageChange("en")}>
            <img
              className="menu__icon"
              src={unitedKingdom}
              alt="united kingdom"
            />
          </li>
        </div>
      </ul>
      <Hamburger />
    </div>
  );
};

export default withTranslation()(Menu);
