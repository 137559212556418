import { useCookies } from "react-cookie";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Cookies = ({ t }: WithTranslation) => {
  const [cookies, setCookie] = useCookies(["cookie-consent"]);

  const acceptCookies = () => {
    setCookie("cookie-consent", "accepted", {
      path: "/",
      maxAge: 365 * 24 * 60 * 60,
    });
  };

  if (cookies["cookie-consent"] === "accepted") {
    return null;
  }

  return (
    <div className="cookie">
      <div className="cookie__content">
        <p className="cookie__text">
          {t("cookie_policy")}
          <Link to="/privacyPolicy" className="cookie__link">
            {t("cookie_policy_link")}
          </Link>
        </p>
        <button className="cookie__button" onClick={acceptCookies}>
          {t("accept")}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(Cookies);
