import AboutUsSection from "../../components/AboutUsSection/AboutUsSection";
import Cookies from "../../components/Cookies/Cookies";
import Entry from "../../components/Entry/Entry";
import Footer from "../../components/Footer/Footer";
import History from "../../components/History/History";
import OfferSection from "../../components/OfferSection/OfferSection";
import Skeleton from "../../components/Skeleton/Skeleton";

const Homepage = () => {
  return (
    <Skeleton>
      <Cookies />
      <Entry />
      <AboutUsSection />
      <OfferSection />
      <History />
      <Footer />
    </Skeleton>
  );
};

export default Homepage;
