import Lottie from 'react-lottie-player';
import animationData from '../../animation.json';

const Animated = () => {
  return (
    <div className="animated">
      <Lottie
        loop
        animationData={animationData}
        play
      />
    </div>
  );
};

export default Animated;
