import { AnimatePresence, motion } from "framer-motion";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Homepage from "../../pages/Homepage/Homepage";
import AboutUs from "../../pages/AboutUs/AboutUs";
import PriceList from "../../pages/PriceList/PriceList";
import Offer from "../../pages/Offer/Offer";
import Projects from "../../pages/Projects/Projects";
import Contact from "../../pages/Contact/Contact";
import PrivacyPolicyPL from "../../pages/PrivacyPolicyPL/PrivacyPolicyPL";
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import PrivacyPolicyEN from "../../pages/PrivacyPolicyEN/PrivacyPolicyEN";

const AnimatedRoutes = ({t}: WithTranslation) => {
  const location = useLocation();

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } },
  };

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  console.log(currentLanguage, " currentLanguage")

  return (
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div {...pageVariants}>
                <Homepage />
              </motion.div>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <motion.div {...pageVariants}>
                {currentLanguage === "en" ? <PrivacyPolicyEN /> : <PrivacyPolicyPL />}
              </motion.div>
            }
          />
          <Route
            path="/aboutUs"
            element={
              <motion.div {...pageVariants}>
                <AboutUs />
              </motion.div>
            }
          />
          <Route
            path="/priceList"
            element={
              <motion.div {...pageVariants}>
                <PriceList />
              </motion.div>
            }
          />
          <Route
            path="/offer"
            element={
              <motion.div {...pageVariants}>
                <Offer />
              </motion.div>
            }
          />
          <Route
            path="/projects"
            element={
              <motion.div {...pageVariants}>
                <Projects />
              </motion.div>
            }
          />
          <Route
            path="/contact"
            element={
              <motion.div {...pageVariants}>
                <Contact />
              </motion.div>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
  );
};

export default withTranslation()(AnimatedRoutes);