import { BrowserRouter } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Cookies from "./components/Cookies/Cookies";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AnimatedRoutes from "./components/AnimatedRoutes/AnimatedRoutes";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navigation />
      <Cookies />
      <AnimatedRoutes />
    </BrowserRouter>
  );
};

export default App;