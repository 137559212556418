import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import { LatLng, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import placeholder from "../../assets/placeholder.png";

const Map = () => {
  const placeholderIcon = new Icon({
    iconUrl: placeholder,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <MapContainer
      center={new LatLng(54.3816781, 18.6128898)}
      zoom={18}
      maxZoom={18}
      style={{ width: "100%", height: "300px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution=""
      />
      <Marker position={[54.3816781, 18.6128898]} icon={placeholderIcon}>
        <Popup>Rondo im. Güntera Grassa, Wrzeszcz Dolny</Popup>
      </Marker>
      <Circle
        center={[54.3816781, 18.6128898]}
        radius={50}
        pathOptions={{ color: "blue", fillColor: "blue", fillOpacity: 0.3 }}
      />
    </MapContainer>
  );
};

export default Map;
