import { withTranslation, WithTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import papers from "../../assets/papers.webp";
import website from "../../assets/website.png";
import mvp from "../../assets/mvp.png";
import advanced from "../../assets/advanced.png";
import digitalProjects from "../../assets/digitalProjects.png";
import consultations from "../../assets/consultations.png";
import businessConsultations from "../../assets/businessConsultations.png";
import documents from "../../assets/documents.png";
import Footer from "../../components/Footer/Footer";

const PriceList = ({ t }: WithTranslation) => {
  const data = [
    {
      icon: website,
      name: t("website_service.name"),
      price: t("website_service.price"),
    },
    {
      icon: mvp,
      name: t("mvp_service.name"),
      price: t("mvp_service.price"),
    },
    {
      icon: advanced,
      name: t("advanced_service.name"),
      price: t("advanced_service.price"),
    },
    {
      icon: digitalProjects,
      name: t("digitalProjects_service.name"),
      price: t("digitalProjects_service.price"),
    },
    {
      icon: consultations,
      name: t("consultations_service.name"),
      price: t("consultations_service.price"),
    },
    {
      icon: businessConsultations,
      name: t("businessConsultations_service.name"),
      price: t("businessConsultations_service.price"),
    },
    {
      icon: documents,
      name: t("documents_service.name"),
      price: t("documents_service.price"),
    },
  ];

  return (
    <div className="priceList">
      <div className="priceList__figure">
        <LazyLoadImage
          className="priceList__image"
          src={papers}
          alt="papers"
          effect="blur"
          width="100%"
          height="100%"
        />
        <h1 className="priceList__title">{t("priceList")}</h1>
      </div>
      <div className="priceList__nameWrapper">
        <p className="priceList__name priceList__name--center">
          {t("pricing_disclaimer")}
        </p>
      </div>
      <div className="priceList__main">
        {data.map((item, index) => (
          <div
            className={`${
              index === 0 ? "priceList__containerTwo" : "priceList__container"
            }`}
            key={index}
          >
            <div className="priceList__box">
              <LazyLoadImage
                className="priceList__icon"
                src={item.icon}
                alt="service-icon"
                effect="blur"
              />
              <div className="priceList__descriptionBox">
                <h3 className="priceList__name">{item.name}</h3>
              </div>
            </div>
            <h4 className="priceList__price">{item.price}</h4>
          </div>
        ))}
      </div>
      <div className="priceList__infoWrapp">
        <p className="priceList__info">{t("pricing_contact")}</p>
      </div>
      <Footer />
    </div>
  );
};

export default withTranslation()(PriceList);
