import { withTranslation, WithTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import personTwo from "../../assets/personTwo.webp";
import implementation from "../../assets/aiTwo.png";
import weAdvise from "../../assets/weAdvise.png";
import designAnalyses from "../../assets/designAnalyses.png";
import ai from "../../assets/ai.png";
import solutions from "../../assets/solutions.png";
import stars from "../../assets/stars.png";
import Footer from "../../components/Footer/Footer";

const Offer = ({ t }: WithTranslation) => {
  return (
    <div className="offer">
      <div className="offer__figure">
        <LazyLoadImage
          className="offer__image"
          src={personTwo}
          alt="personTwo"
          effect="blur"
          width="100%"
          height="100%"
        />
        <h1 className="offer__title">{t("offer")}</h1>
      </div>
      <div className="offer__main">
        <p className="offer__info">{t("innovation_and_specialization")}</p>
        <div className="offer__wrapper">
          <div className="offer__container">
            <LazyLoadImage
              className="offer__icon"
              src={stars}
              alt="stars"
              effect="blur"
            />
            <LazyLoadImage
              className="offer__photo"
              src={implementation}
              alt="implementation"
              effect="blur"
            />
            <div className="offer__descriptionBox">
              <h2 className="offer__name">{t("software_development")}</h2>
              <p className="offer__text">
                {t("software_development_description")}
              </p>
            </div>
          </div>
          <div className="offer__containerTwo">
            <LazyLoadImage
              className="offer__photo"
              src={ai}
              alt="ai"
              effect="blur"
            />
            <div className="offer__descriptionBox">
              <h2 className="offer__name">{t("ai")}</h2>
              <p className="offer__text">
                {t("ai_and_automation_description")}
              </p>
            </div>
          </div>
          <div className="offer__container">
            <LazyLoadImage
              className="offer__photo"
              src={weAdvise}
              alt="weAdvise"
              effect="blur"
            />
            <div className="offer__descriptionBox">
              <h2 className="offer__name">{t("technology_consulting")}</h2>
              <p className="offer__text">
                {t("technology_consulting_description")}
              </p>
            </div>
          </div>
          <div className="offer__containerTwo">
            <LazyLoadImage
              className="offer__photo"
              src={designAnalyses}
              alt="designAnalyses"
              effect="blur"
            />
            <div className="offer__descriptionBox">
              <h2 className="offer__name">{t("project_valuation")}</h2>
              <p className="offer__text">
                {t("project_valuation_description")}
              </p>
            </div>
          </div>
          <div className="offer__container">
            <LazyLoadImage
              className="offer__photo"
              src={solutions}
              alt="solutions"
              effect="blur"
            />
            <div className="offer__descriptionBox">
              <h2 className="offer__name">{t("tech_innovation_research")}</h2>
              <p className="offer__text">{t("tech_innovation_description")}</p>
            </div>
          </div>
        </div>
        <p className="offer__infoTwo">{t("whyUs")}</p>
        <ul className="offer__list">
          <li className="offer__item">
            {t("flexibility_and_personalized_approach")}
          </li>
          <li className="offer__item">{t("transparency_and_honesty")}</li>
          <li className="offer__item">{t("philanthropic_approach")}</li>
          <li className="offer__item">
            {t("modern_technologies_and_research")}
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default withTranslation()(Offer);
