import { withTranslation, WithTranslation } from "react-i18next";
import person from "../../assets/person.webp";
import laptopTwo from "../../assets/laptopTwo.webp";
import office from "../../assets/office.png";
import team from "../../assets/team.png";
import moon from "../../assets/moon.png";
import Team from "../../components/Team/Team";
import Faq from "../../components/Faq/Faq";
import Footer from "../../components/Footer/Footer";

const AboutUs = ({ t }: WithTranslation) => {
  return (
    <div className="aboutUs">
      <div className="aboutUs__figure">
        <img className="aboutUs__image" src={person} alt="person" />
        <h1 className="aboutUs__title">{t("aboutUs")}</h1>
      </div>
      <div className="aboutUs__main">
        <div className="aboutUs__wrapper">
          <div className="aboutUs__container">
            <div className="aboutUs__figureTwo">
              <img
                className="aboutUs__imageTwo"
                src={laptopTwo}
                alt="laptopTwo"
              />
              <img className="aboutUs__icon" src={moon} alt="moon" />
            </div>
            <div className="aboutUs__figureTwo">
              <img
                className="aboutUs__imageTwo"
                src={office}
                alt="office"
              />
            </div>
            <div className="aboutUs__figureThree">
              <img
                className="aboutUs__imageThree"
                src={team}
                alt="team"
              />
            </div>
          </div>
          <div className="aboutUs__box">
            <p className="aboutUs__title aboutUs__title--color">
              {t("aboutUs")}
            </p>
            <p className="aboutUs__description">{t("company_description")}</p>

            <p className="aboutUs__description">{t("company_vision")}</p>
            <p className="aboutUs__description">
              {t("company_specialization")}
            </p>
            <p className="aboutUs__description">
              {t("innovation_and_research")}
            </p>
            <p className="aboutUs__description">{t("values_and_ethics")}</p>
            <p className="aboutUs__description">{t("mission_statement")}</p>
          </div>
        </div>
      </div>
      <Team />
      <Faq />
      <Footer />
    </div>
  );
};

export default withTranslation()(AboutUs);
