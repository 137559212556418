import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import privacyPolicy from "../../assets/privacyPolicy.png";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <ToastContainer />
      <div className="contact__figure">
        <LazyLoadImage
          className="contact__image"
          width="100%"
          height="100%"
          src={privacyPolicy}
          alt="flower"
          effect="blur"
        />
        <h1 className="privacy__title">Polityka Prywatności</h1>
      </div>
      <p>
        Polityka prywatności opisuje zasady przetwarzania przez nas informacji
        na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw.
        cookies.
      </p>

      <section>
        <h2>1. Informacje ogólne</h2>
        <p>
          Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem
          url:{" "}
          <a
            href="https://vesselhost.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            vesselhost.com
          </a>
          . Operatorem serwisu oraz Administratorem danych osobowych jest:
          Vesselhost Sp. z o.o. Wajdeloty 27/1A, 80-437 Gdańsk.
        </p>
        <p>
          Adres kontaktowy poczty elektronicznej operatora:{" "}
          <a href="mailto:vesselhost1@gmail.com">vesselhost1@gmail.com</a>
        </p>
        <p>
          Operator jest Administratorem Twoich danych osobowych w odniesieniu do
          danych podanych dobrowolnie w Serwisie.
        </p>
        <p>
          Serwis wykorzystuje dane osobowe w następujących celach:
          <ul>
            <li>Obsługa zapytań przez formularz</li>
            <li>Realizacja zamówionych usług</li>
            <li>Prezentacja oferty lub informacji</li>
          </ul>
        </p>
        <p>
          Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich
          zachowaniu w następujący sposób:
          <ul>
            <li>
              Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają
              wprowadzone do systemów Operatora.
            </li>
            <li>
              Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
              „ciasteczka”).
            </li>
          </ul>
        </p>
      </section>

      <section>
        <h2>2. Wybrane metody ochrony danych stosowane przez Operatora</h2>
        <p>
          Miejsca logowania i wprowadzania danych osobowych są chronione w
          warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane
          logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze
          użytkownika i mogą być odczytane jedynie na docelowym serwerze.
        </p>
        <p>
          Dane osobowe przechowywane w bazie danych są zaszyfrowane w taki
          sposób, że jedynie posiadający Operator klucz może je odczytać. Dzięki
          temu dane są chronione na wypadek wykradzenia bazy danych z serwera.
        </p>
        <p>
          Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja
          hashująca działa jednokierunkowo – nie jest możliwe odwrócenie jej
          działania, co stanowi obecnie współczesny standard w zakresie
          przechowywania haseł użytkowników.
        </p>
        <p>Operator okresowo zmienia swoje hasła administracyjne.</p>
        <p>
          W celu ochrony danych Operator regularnie wykonuje kopie
          bezpieczeństwa.
        </p>
        <p>
          Istotnym elementem ochrony danych jest regularna aktualizacja
          wszelkiego oprogramowania, wykorzystywanego przez Operatora do
          przetwarzania danych osobowych, co w szczególności oznacza regularne
          aktualizacje komponentów programistycznych.
        </p>
      </section>

      <section>
        <h2>3. Hosting</h2>
        <p>
          Serwis jest hostowany (technicznie utrzymywany) na serwerach
          operatora: cyberFolks.pl. Firma hostingowa w celu zapewnienia
          niezawodności technicznej prowadzi logi na poziomie serwera. Zapisowi
          mogą podlegać:
          <ul>
            <li>
              zasoby określone identyfikatorem URL (adresy żądanych zasobów –
              stron, plików),
            </li>
            <li>czas nadejścia zapytania,</li>
            <li>czas wysłania odpowiedzi,</li>
            <li>
              nazwę stacji klienta – identyfikacja realizowana przez protokół
              HTTP,
            </li>
            <li>
              informacje o błędach jakie nastąpiły przy realizacji transakcji
              HTTP,
            </li>
            <li>
              adres URL strony poprzednio odwiedzanej przez użytkownika (referer
              link) – w przypadku, gdy przejście do Serwisu nastąpiło przez
              odnośnik,
            </li>
            <li>informacje o przeglądarce użytkownika,</li>
            <li>informacje o adresie IP,</li>
            <li>
              informacje diagnostyczne związane z procesem samodzielnego
              zamawiania usług poprzez rejestratory na stronie,
            </li>
            <li>
              informacje związane z obsługą poczty elektronicznej kierowanej do
              Operatora oraz wysyłanej przez Operatora.
            </li>
          </ul>
        </p>
      </section>

      <section>
        <h2>
          4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych
        </h2>
        <p>
          W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane
          osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania
          zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na
          Administratorze. Dotyczy to takich grup odbiorców: operatorzy
          rozwiązania typu chat online firmy, świadczące usługi marketingu na
          rzecz Administratora.
        </p>
        <p>
          Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż
          jest to konieczne do wykonania związanych z nimi czynności określonych
          osobnymi przepisami (np. o prowadzeniu rachunkowości). W odniesieniu
          do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3
          lata.
        </p>
        <p>
          Przysługuje Ci prawo żądania od Administratora:
          <ul>
            <li>dostępu do danych osobowych Ciebie dotyczących,</li>
            <li>ich sprostowania,</li>
            <li>usunięcia,</li>
            <li>ograniczenia przetwarzania,</li>
            <li>oraz przenoszenia danych.</li>
          </ul>
        </p>
        <p>
          Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania
          wskazanego w pkt 3.2 wobec przetwarzania danych osobowych w celu
          wykonania prawnie uzasadnionych interesów realizowanych przez
          Administratora, w tym profilowania, przy czym prawo sprzeciwu nie
          będzie mogło być wykonane w przypadku istnienia ważnych prawnie
          uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
          interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub
          obrony roszczeń.
        </p>
        <p>
          Na działania Administratora przysługuje skarga do Prezesa Urzędu
          Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
        </p>
        <p>
          Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi
          Serwisu.
        </p>
        <p>
          W stosunku do Ciebie mogą być podejmowane czynności polegające na
          zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
          świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
          przez Administratora marketingu bezpośredniego.
        </p>
      </section>

      <section>
        <h2>5. Informacje w formularzach</h2>
        <p>
          Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym
          dane osobowe, o ile zostaną one podane.
        </p>
        <p>
          Serwis może zapisać informacje o parametrach połączenia (oznaczenie
          czasu, adres IP).
        </p>
        <p>
          Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą
          powiązanie danych w formularzu z adresem e-mail użytkownika
          wypełniającego formularz. W takim wypadku adres e-mail użytkownika
          pojawia się wewnątrz adresu url strony zawierającej formularz.
        </p>
        <p>
          Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji
          konkretnego formularza, np. w celu dokonania procesu obsługi
          zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp.
          Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do
          czego on służy.
        </p>
      </section>

      <section>
        <h2>6. Logi Administratora</h2>
        <p>
          Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu.
          Dane te są wykorzystywane w celu administrowania serwisem.
        </p>
      </section>

      <section>
        <h2>7. Informacja o plikach cookies</h2>
        <p>
          Serwis korzysta z plików cookies. Pliki cookies stanowią dane
          informatyczne, w szczególności pliki tekstowe, które przechowywane są
          w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do
          korzystania ze stron internetowych Serwisu.
        </p>
        <p>
          Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu
          pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
        </p>
        <p>
          Pliki cookies wykorzystywane są w następujących celach:
          <ul>
            <li>
              utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki
              której użytkownik nie musi na każdej podstronie Serwisu ponownie
              wpisywać loginu i hasła.
            </li>
          </ul>
        </p>
        <p>
          W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies:
          „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies
          „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu
          końcowym Użytkownika do czasu wylogowania, opuszczenia strony
          internetowej lub wyłączenia oprogramowania (przeglądarki
          internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu
          końcowym Użytkownika przez czas określony w parametrach plików cookies
          lub do czasu ich usunięcia przez Użytkownika.
        </p>
      </section>

      <section>
        <h2>
          8. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?
        </h2>
        <p>
          Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić
          ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików
          cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
          utrzymania preferencji użytkownika może utrudnić, a w skrajnych
          przypadkach może uniemożliwić korzystanie ze stron www.
        </p>
        <p>
          W celu zarządzania ustawienia cookies wybierz z listy poniżej
          przeglądarkę internetową, której używasz i postępuj zgodnie z
          instrukcjami:
        </p>
        <ul>
          <li>Edge</li>
          <li>Internet Explorer</li>
          <li>Chrome</li>
          <li>Safari</li>
          <li>Firefox</li>
          <li>Opera</li>
        </ul>
        <p>Urządzenia mobilne:</p>
        <ul>
          <li>Android</li>
          <li>Safari (iOS)</li>
          <li>Windows Phone</li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
