import { createContext, Dispatch, ReactNode, SetStateAction } from "react";

export interface APIContextProviderProps {
  children: ReactNode;
}

type APIContextTypes = {
  isHamburgerMenu: boolean;
  setIsHamburgerMenu: Dispatch<SetStateAction<boolean>>;
};

const defaultAPIContext: APIContextTypes = {
  isHamburgerMenu: false,
  setIsHamburgerMenu: () => {},
};

export const APIContext = createContext<APIContextTypes>(defaultAPIContext);
