import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import groups from "../../assets/groups.webp";
import apple from "../../assets/apple.webp";
import laptop from "../../assets/laptop.webp";

const OfferSection = ({ t }: WithTranslation) => {
  return (
    <div className="offerSection">
      <div className="offerSection__main">
        <div className="offerSection__wrapper">
          <div className="offerSection__box">
            <h2 className="offerSection__title">{t("offer")}</h2>
            <p className="offerSection__description">
              {t("services_offered_details")}
            </p>
            <div className="offerSection__linkBox">
              <Link className="offerSection__link" to="/contact">
                {t("more")}
              </Link>
            </div>
          </div>
          <div className="offerSection__imagesBox">
            <img className="offerSection__imageOne" src={groups} alt="groups" />
            <img className="offerSection__imageOne" src={apple} alt="apple" />
          </div>
          <img className="offerSection__imageTwo" src={laptop} alt="laptop" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(OfferSection);
