import React, { ReactNode } from 'react';

interface SkeletonPropsType {
  children?: ReactNode;
}

const Skeleton: React.FC<SkeletonPropsType> = ({ children }) => {
  return <div className="skeleton">{children || null}</div>;
};

export default Skeleton;
